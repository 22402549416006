<template>
  <div v-loading="loading" class="file" style="">
    <div :style="{ paddingTop: List.length > 0 ? '0' : '150px' }" class="logoBox">
      <div class="searchLogo" v-if="List.length === 0 && isShow == false">
        <el-image :src="require('@/assets/zytlogo.png')" style="width: 170px;"></el-image>
      </div>
      <div class="searchBox">
        <el-autocomplete v-model="queryParam[0].Keyword" :fetch-suggestions="querySearch" class="input" clearable
          placeholder="请输入项目名称进行查询" @keydown.enter.native="search"></el-autocomplete>
        <el-button class="btn" size="mini" type="primary" @click="search">查询</el-button>
      </div>
    </div>
    <div v-if="List.length > 0 && isShow == true" class="projectContent">
      <el-tabs v-model="activeName">
        <el-tab-pane label="查询结果" name="All">
          <div class="AllBox" :style="{ height: getSearchResultHight + 'px', overflow: 'auto' }">
            <el-card v-for="item in List" :key="item.Id" :body-style="{ padding: '0px' }" class="proListCard"
              shadow="hover" @click.native="handleView(item)">
              <el-image :src=getProjectImg(item) class="proListImg" fit="cover"></el-image>
              <div v-if="item.CreatorId === vipuser.Id" class="delIcon" @click.stop="handleDel(item)">
                <i class="el-icon-delete"></i>
              </div>
              <div v-if="!item.IsCollect" class="star" @click.stop="collectProject(item)">
                <i class="el-icon-star-off"></i>
              </div>
              <div v-else class="starOn" @click.stop="collectCancelProject(item)">
                <i class="el-icon-star-on"></i>
              </div>
              <div style="padding:10px">
                <span class="proName">{{ item.Name }}</span>
              </div>
              <div class="probottom clearfix">
                <span class="footerleft">{{ item.CreatorRealName }}</span>&nbsp;&nbsp;
                <span class="footerright">{{ item.ModifyDate ? timeDataFormat(item.ModifyDate, 'YYYY-MM-DD HH:mm') : ""
                  }}</span>
              </div>
            </el-card>
          
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 底部分页 -->
      <div style="display:flex;justify-content: flex-end;margin-top: 30px;">
        <pagination :limit.sync="pagination.PageRows" :page.sync="pagination.PageIndex" :total="pagination.total"
          @pagination="currentChange" />
      </div>
    </div>
    <div v-if="isShow == false">
      <el-empty description="请搜索项目名称"></el-empty>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
import folderImg from "@/assets/folder.png";
import addfolderImg from "@/assets/Addfolder.png";

export default {
  data() {
    return {
      vipuser: {},
      // 是否显示空状态
      isShow: false,
      activeName: "All",
      restaurants: [],
      List: [],
      loading: false,
      pagination: {
        PageIndex: 1,
        PageRows: 20,
        total: 0,
        SortField: "CreateTime",
        SortType: "desc",
        pageSizeOptions: [10, 20, 30, 50],
      },
      queryParam: [{
        Condition: "Name",
        Keyword: "",
      }],
      folderImg
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    // 绑定项目图片
    getProjectImg: () => {
      return (item) => (item ? item.ProjectImg ? item.ProjectImg : folderImg : addfolderImg);
    },
    getSearchResultHight() {
      return Number(document.body.offsetHeight - 300)
    }
  },
  mounted() {
    this.vipuser = this.userInfo
  },
  methods: {
    // 查看项目
    handleView(item) {
      let pId = item.ProjectId || item.Id;
      this.$router.push("/Template?prop=view&id=" + pId);
    },
    querySearch(queryString, cb) {
      const restaurants = this.restaurants;
      const results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    currentChange(data) {
      this.pagination.PageIndex = data.pageIndex;
      this.pagination.PageRows = data.pageSize;
      this.search();
    },
    search() {
      const that = this;
      this.loading = true;
      this.isShow = true
      this.$http.post("/Base_ProjectWork/Base_Project/GetProjectListForSearch", {
        PageIndex: this.pagination.PageIndex,
        PageRows: this.pagination.PageRows,
        SortField: this.pagination.SortField,
        SortType: this.pagination.SortType,
        search: this.queryParam
      }).then((res) => {
        this.pagination.total = res.Total
        if (res.Success) {
          if (res.Data == null) {
            this.List = []
            setTimeout(function () {
              that.loading = false;
              that.isShow = false
            }, 500)
            return
          }
          this.List = res.Data;
          setTimeout(function () {
            that.loading = false;
          }, 500)
          this.isShow = true
        }
      });
    },
    // 删除项目
    handleDel(obj) {
      this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.post("/Base_ProjectWork/Base_Project/AppDeleteData", [obj.Id]).then((res) => {
          if (res.Success) {
            this.search();
            this.$message.success("操作成功");
          } else this.$message.error(res.Msg);
        });
      }).catch(() => {
      });
    },
    // 收藏项目
    collectProject(item) {
      this.$http.post("/Base_ProjectWork/Base_ProjectCollect/SaveData", {
        ProjectId: item.Id,
        UserId: this.vipuser.Id,
      }).then((res) => {
        if (res.Success) {
          item.IsCollect = true;
          // this.$message.success("操作成功");
        } else this.$message.error(res.Msg);
      });
    },
    // 取消收藏项目
    collectCancelProject(item) {
      this.$http.post("/Base_ProjectWork/Base_ProjectCollect/DeleteDataByCondition",
        [
          { Condition: 'ProjectId', Keyword: item.Id },
          { Condition: 'UserId', Keyword: this.vipuser.Id }
        ]).then((res) => {
          if (res.Success) {
            item.IsCollect = false;
            // this.$message.success("操作成功");
          } else this.$message.error(res.Msg);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  font-weight: bold;
}

::v-deep .el-tabs__item.is-active {
  color: #000;
}

.file {
  .logoBox {
    width: 600px;
    margin: 0 auto;

    .searchLogo {
      margin: 0 auto 30px;
      justify-content: center;
      display: flex;
      width: 100%;
    }

    .searchBox {
      display: flex;
      margin-top: 50px;

      .input {
        width: 600px;
      }

      ::v-deep .el-input__inner {
        border-radius: 5px 0 0 5px;
      }

      .btn {
        border-radius: 0 5px 5px 0;
      }
    }
  }

  // 主体内容
  .projectContent {
    box-sizing: border-box;
    padding: 0 48px;

    .AllBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      padding-top: 5px;

      .proListCard {
        padding: 0;
        position: relative;
        width: 220px;
        height: 177px;
        margin: 5px;

        &:hover {
          box-shadow: 5px 5px 5px rgb(38 38 38 / 50%),
            5px 5px 10px rgb(38 38 38 / 30%);

          .delIcon {
            opacity: 1;
          }

          .star {
            opacity: 1;
          }
        }

        .proListImg {
          width: 220px;
          height: 100px;
          display: block;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .delIcon {
          display: block;
          background: rgba(255, 0, 0, 0.6);
          width: 30px;
          height: 25px;
          line-height: 22px;
          text-align: center;
          font-size: 20px;
          border-radius: 0 0 0 10%;
          opacity: 0;
          transition: all 100ms linear 0s;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1000;
          color: white;

          &:hover {
            background: rgba(255, 0, 0, 1);
          }

        }

        .star {
          background-color: #ffffff;
          width: 30px;
          height: 25px;
          line-height: 22px;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          border-radius: 0 0 10% 0;
          z-index: 1000;
          font-size: 20px;

          &:hover {
            background: #ecf5f9;
            color: #f18d20;
          }
        }

        .starOn {
          background-color: rgba(255, 120, 0, 0.9);
          width: 30px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
          border-radius: 0 0 10% 0;
          z-index: 1000;
          font-size: 22px;
          color: #ffffff;

          &:hover {
            background-color: rgba(255, 165, 0, 1);
          }
        }

        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }

        .clearfix:after {
          clear: both
        }

        .proName {
          flex: 1;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: bold;
        }

        .probottom {
          padding: 10px;

          .footerleft {
            float: left;
            font-size: 12px;
            color: #999;
          }

          .footerright {
            float: right;
            font-size: 12px;
            color: #999;
          }

          .probutton {
            padding: 0;
            font-size: 12px;
            float: right;
          }
        }
      }
    }
  }
}
</style>
